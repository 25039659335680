/**
 * Direttiva che crea il subheader lato amministrazione
 */
export interface ISubHeaderAdminDirectiveScope extends ng.IScope {
	width: number;
	hideMenuButtons: boolean;
	isSearchBarOpen: boolean;
	openSearchBar: Function;
	openSidenavMenuAdmin: Function;
	searchContent: string;
	goToMonitoring: Function;
	goToHomeAdmin: Function;
	goToNewsAdmin: Function;
	goToCourses: Function;
	goToUsers: Function;
	bgacademyApplicationData: any;
	goToProjects: Function;
	goToGroups: Function;
	goToSubscriptions: Function;
	goToUserMode: Function;
	goToEditCourse: Function;
	goToEditWebinar: Function;
	isSupervisor: boolean;
}
angular.module('app').directive("subHeaderAdmin", ($window, $mdSidenav, $state, BgacademyApplicationData, $stateParams, $sessionStorage, SessionStorageService) => {
	return {
		link: link,
		restrict: 'AE',
		templateUrl: 'app/shared/subHeaderAdmin/subHeaderAdmin.html'
	};
	function link($scope: ISubHeaderAdminDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto principale
		$scope.bgacademyApplicationData = BgacademyApplicationData;

		// Verifica se l'utente è un supervisore
		$scope.isSupervisor = $sessionStorage.isSupervisor;

		// Apertura Sidenav di sinistra
		$scope.openSidenavMenuAdmin = () => {
			$mdSidenav('sidenavMenuAdmin').toggle();
		}

		// Porta alla pagina di modifica corso
		$scope.goToEditCourse = () => {
			$state.go('app.bgacademyApp.editCourseAdmin', { courseId: $stateParams.courseId, courseDateId: $stateParams.courseDateId });
		}

		// Porta alla pagina di modifica webinar
		$scope.goToEditWebinar = () => {
			$state.go('app.bgacademyApp.editWebinarAdmin', { webinarId: $stateParams.webinarId, webinarDateId: $stateParams.webinarDateId });
		}

		// Porta alla pagina di Home
		$scope.goToHomeAdmin = () => {
			$state.go('app.bgacademyApp.homeAdmin');
		}

		// Porta alla pagina delle impostazioni utente
		$scope.goToUserMode = () => {
			$state.go('app.bgacademyApp.userMode');
		}

		// Porta alla pagina di monitoraggio
		$scope.goToMonitoring = () => {
			$state.go('app.bgacademyApp.monitoringAdmin.users');
		}

		// Porta alla pagina delle news
		$scope.goToNewsAdmin = () => {
			$state.go('app.bgacademyApp.newsListAdmin');
		}

		// Porta alla pagina dei corsi
		$scope.goToCourses = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count();

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.courseListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.courseListAdmin');
			}
		}

		// Porta alla pagina dei progett
		$scope.goToProjects = () => {
			$state.go('app.bgacademyApp.projectListAdmin');
		}

		// Porta alla pagina delle iscrizioni
		$scope.goToSubscriptions = () => {
			$state.go('app.bgacademyApp.subscriptionListAdmin');
		}

		// Porta alla pagina degli utenti
		$scope.goToUsers = (startNewSerch: boolean) => {
			if (!startNewSerch) {
				// Verifico quanti elementi ho nel Session Storage
				let count = SessionStorageService.count("USERS_SEARCH");

				// ci sarà sempre un altro elemento nel session storage, dunque decremento il risultato
				count = count ? count : 0;

				$state.go('app.bgacademyApp.userListAdmin', { searchId: count });
			} else {
				$state.go('app.bgacademyApp.userListAdmin');
			}
		}

		// Porta alla pagina dei gruppi
		$scope.goToGroups = () => {
			$state.go('app.bgacademyApp.groupListAdmin');
		}

		// Di default la barra per la ricerca è chiusa
		$scope.isSearchBarOpen = false;

		// Traduzione per ricerca contenuto
		$scope.searchContent = 'Ricerca un contenuto';

		// Toggle barra di ricerca
		$scope.openSearchBar = () => {
			$scope.isSearchBarOpen = !$scope.isSearchBarOpen;
		}

		// Da 790px in giù, le voci del menu si nascondono lasciando spazio solo all'Hamburge menu
		angular.element($window).bind('resize', () => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})

		// Stessa cosa al caricamento della pagina
		angular.element(document).ready(() => {
			$scope.width = $window.innerWidth;

			if ($scope.width < 790) {
				$scope.hideMenuButtons = true;
			} else {
				$scope.hideMenuButtons = false;
			}
			$scope.$digest();
		})
	}
});